import { insulationThickness, getPlasterGrainsForButtons } from '@/store/filter/values'

export default {
  image: 'sw6.png',
  pdf: 'sw6.pdf',
  productData: {
    name: 'Aufbau 6: WDVS – Steinwolle-Platte, organisch',
    shorthand: "sw6",
    serialNumber: 'WA-EPS-006',
    
    construction: {
      composition: [
        {
          title: { de: 'Ziel / Wunsch' },
          output: 'Effizient Energie sparen',
          type: 'goal',
          value: 'goal-1'
        },

        {
          title: { de: 'System' },
          output: 'geklebt + gedübelt',
          type: 'system', 
          value: 'system-1'
        },

        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A Mörtel Weiss',
          type: 'insulationGlue', 
          value: 'insulationGlue-1'
        },
        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A UniMörtel',
          type: 'insulationGlue', 
          value: 'insulationGlue-2'
        },
        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A LeichtMörtel',
          type: 'insulationGlue', 
          value: 'insulationGlue-3'
        },

        {
          title: { de: 'Dämmstoff' },
          output: 'MP 035',
          type: 'insulation', 
          value: 'insulation-5'
        },
        ...insulationThickness('insulation-6'),

        {
          title: { de: 'Dübel' },
          output: 'HTS-M',
          type: 'dowel',  
          value: 'dowel-1'
        },
        {
          title: { de: 'Dübel' },
          output: 'HTS-P',
          type: 'dowel',  
          value: 'dowel-2'
        },
        {
          title: { de: 'Dübel' },
          output: 'HTR-M',
          type: 'dowel',  
          value: 'dowel-3'
        },

        {
          title: { de: 'Dübelvergrößerung' },
          output: 'HDT 90',
          type: 'dowelEnlargement', 
          value: 'dowelEnlargement-1'
        },
        {
          title: { de: 'Dübelvergrößerung' },
          output: 'HDT 140',
          type: 'dowelEnlargement', 
          value: 'dowelEnlargement-2'
        },
        
        {
          title: { de: 'Armierungsmasse' },
          output: 'FlexSpachtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-3'
        },

        {
          title: { de: 'Armierungsgewebe' },
          output: 'Armierungsgewebe Fein',
          type: 'reinforcingFabric', 
          value: 'reinforcingFabric-1'
        },


        {
          title: { de: 'Putzgrundierung' },
          output: 'PutzGrund',
          type: 'renderPrimer', 
          value: 'renderPrimer-1'
        },

        {
          title: { de: 'Oberputz' },
          output: 'SilikonPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-1'
        },
        ...getPlasterGrainsForButtons('finishingPlaster-1'),
        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST SiliconTherm',
          type: 'facadePaint', 
          value: 'facadePaint-1'
        },

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST LotusanTherm',
          type: 'facadePaint', 
          value: 'facadePaint-2'
        },
      ],
      sum: {
        output: '330,5',
        value: 330.5
      }
    }
  }
}
