import { insulationThickness, getPlasterGrainsForButtons } from '@/store/filter/values'

export default {
  image: 'sw11.png',
  pdf: 'sw11.pdf',
  productData: {
    name: 'Aufbau 11: Sanierung alter Fassaden ohne Dämmung, rein mineralisch',
    shorthand: "sw11",
    serialNumber: 'WA-EPS-011',
    
    construction: {
      composition: [
        {
          title: { de: 'Ziel / Wunsch' },
          output: 'Sanierung alter Fassaden ohne Dämmung',
          type: 'goal',
          value: 'goal-3'
        },

        {
          title: { de: 'Armierungsmasse' },
          output: 'K+A Mörtel Weiss',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-1'
        },
        {
          title: { de: 'Armierungsmasse' },
          output: 'K+A UniMörtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-2'
        },
        {
          title: { de: 'Armierungsmasse' },
          output: 'K+A LeichtMörtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-4'
        },
        
        {
          title: { de: 'Armierungsgewebe' },
          output: 'Armierungsgewebe Fein',
          type: 'reinforcingFabric', 
          value: 'reinforcingFabric-1'
        },

        {
          title: { de: 'Putzgrundierung' },
          output: 'PutzGrund',
          type: 'renderPrimer', 
          value: 'renderPrimer-1'
        },

        {
          title: { de: 'Oberputz' },
          output: 'SilikonPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-1'
        },
        
        {
          title: { de: 'Oberputz' },
          output: 'MineralPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-2'
        },
        {
          title: { de: 'Oberputz' },
          output: 'K+A UniMörtel',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-3'
        },
        ...getPlasterGrainsForButtons('finishingPlaster-1'),

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST SiliconTherm',
          type: 'facadePaint', 
          value: 'facadePaint-1'
        },

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST LotusanTherm',
          type: 'facadePaint', 
          value: 'facadePaint-2'
        },
      ],
      sum: {
        output: '330,5',
        value: 330.5
      }
    }
  }
}
