import de from './de'

// const defaultLanguage = 'de'

const getString = key => {
  const keys = key.split('.')
  let ref = de.strings
  for (let i = 0; i < keys.length; i++) {
    if (ref[keys[i]]) ref = ref[keys[i]]
  }
  if (typeof ref === 'string') return ref
  return ''
}

const getSectionTitle = section => {
  if (de.sections[section]) return de.sections[section].title
  return ''
}

const getSectionValueTitle = (section, value) => {
  if (de.sections[section]) {
    const titles = de.sections[section].items.filter(obj => obj.value === value)
    if (titles.length > 0) return titles[0].title
  }
  return ''
}

export {
  getString,
  getSectionTitle,
  getSectionValueTitle,
  de
}

export default de
