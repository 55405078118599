
import axios from 'axios'

import { getString } from '../../language'

function getRelevantPdfInformation(allPdfInfo) {
}

export default {

  displayForm: context => {
    context.commit('displayForm')
    setTimeout(() => document.querySelector('[data-target="contact-form"]').scrollIntoView({
      behavior: 'smooth'
    }), 50) // delay to ensure mounted form without observing component mount
    return true
  },

  hideForm: context => context.commit('hideForm'),

  setFormData (context, payload) {
    context.commit('setFormData', payload)
  },

  sendForm (context) {
    // console.log(context.rootState.results.items.map(item => item.pdf))
    // get all objects from context.rootState.filter which names start with pdf and which values inside the object has not length 0
    const getPdfInfoFromStore = () => {
      const pdfInfo = context.rootState.results.items.map(item => {
        const pdfKey = 'pdf_' + item.pdf.replace('.pdf', '')
        return context.rootState.filter[pdfKey]
      })
      return pdfInfo
    }
    
    
    const formData = {
      mail: {
        'Vorname': context.state.formData.firstname,
        'Nachname': context.state.formData.lastname,
        'Firma / Unternehmer': context.state.formData.company,
        'Strasse / Hausnummer': context.state.formData.street,
        'PLZ': context.state.formData.postcode,
        'Ort': context.state.formData.city,
        'Telefon': context.state.formData.phone,
        'E-Mail': context.state.formData.email,
        'Ihre Nachricht': context.state.formData.text, 
        "Händler": context.state.formData.dealer,
        // 'pdf_info': getPdfInfoFromStore()
      }
    }
    console.log(context.state.formData)
    context.commit('setFormSendState', true)

    axios({
      method: 'post',
      url:  window.apiPrefix + '/api/mail',
      data: {
        mail: formData.mail,
        pdfInfo: getPdfInfoFromStore()
      },
      // responseType: 'arraybuffer'
    })
      .then(resp => {
        console.log(resp.data)
        if (resp.data.success) context.commit('setFormSuccess', { message: getString('contact.alert.success') })
        else context.commit('setFormError', { message: getString('contact.alert.error') })
      })
      .catch(err => {
        console.log(err)
        context.commit('setFormError', { message: getString('contact.alert.error') })
      })
      .finally(() => context.commit('setFormSendState', false))
  }

}
