<template>
<div class="container-fluid">
  <div class="bg-appRowBg p-1 text-white row font-weight-bold filter-header pl-0">
    <div class="col-12 pl-0">
      <h5 class="p-0 pl-2 m-0">
        3. Ergebnis ({{
          listLength +
          ' ' +
          countResult +
          ' ' +
          (listLength > 1 || listLength == 0 ? meetPlural : meetSingular) +
          ' ' +
          selectionCriteria +
          '. ' +
          alternatives
        }})
      </h5>
    </div>
  </div>
</div>
  
</template>

<script>
import { getString } from '../../language'

export default {
  name: 'StoCscResultHead',
  props: {
    listLength: Number,
  },
  data: function () {
    return {
      countResult: getString('result.headline.countResult'),
      meetSingular: getString('result.headline.meet.singular'),
      meetPlural: getString('result.headline.meet.plural'),
      selectionCriteria: getString('result.headline.selectionCriteria'),
      alternatives: getString('result.headline.alternatives'),
    }
  },
}
</script>
