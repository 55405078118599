<template>
  <div class="container-fluid">
    <div class="form bg-white pl-0 row my-4 py-2">
      <div class="col-md-6">
        <div class="row align-items-center mt-3">
          <label for="statsForm-projectName" class="col-5 col-sm-4 col-md-5 col-lg-4 col-xl-3 mb-0 pb-0 font-weight-bold">{{ projectName }}</label>
          <input
            type="text"
            class="form-control col-6"
            id="statsForm-projectName"
            :value="formData.projectName"
            @focus="focus('projectName')"
            @input="setprojectName"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="row align-items-center mt-3">
          <label for="statsForm-projectSize" class="col-5 col-sm-4 col-md-5 col-lg-4 col-xl-3 mb-0 pb-0 font-weight-bold" v-html="projectSize"/>
          <input
            class="form-control col-2  "
            id="statsForm-projectSize"
            :value="formData.projectSize"
            @focus="focus('projectSize')"
            @input="setprojectSize"
            type="number"
            pattern="[0-9]+([\.,][0-9]+)?" step="0.01"
            required
          />
          <span class="pl-2" v-html="projectSizeSuffix"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getString } from '../../language'

export default {
  name: 'StoCscProjectStats',
  data: function () {
    return {
      headline: getString('projectStats.headline'),
      projectSize: getString('projectStats.size'),
      projectSizeSuffix: getString('projectStats.sizeSuffix'),
      projectName: getString('projectStats.name'),
    }
  },
  computed: {
    ...mapGetters({
      formData: 'stats/formData',
      formState: 'stats/formState',
    }),
  },
  methods: {
    focus(name) {
      this.$gtm.trackEvent({
        event: 'editForm',
        category: 'stats',
        action: 'focus',
        label: getString('stats.input.' + name).toLowerCase(),
        value: null,
      })
    },
    resetFilter: function (value) {
      this.$gtm.trackEvent({
        event: 'clickElement',
        category: 'eigenschaften',
        action: 'button',
        label: 'Eigenschaften zurücksetzen [X]',
        value: null,
      })

      this.$store.dispatch('filter/resetFilter')
      this.$store.dispatch('contact/hideForm')
    },
    setprojectName(input) {
      this.$store.dispatch('stats/setFormData', {
        name: 'name',
        value: input.target.value,
      })
    },
    setprojectSize(input) {
      this.$store.dispatch('stats/setFormData', {
        name: 'size',
        value: parseFloat(input.target.value.replaceAll(".", "").replaceAll(",", "."))
      })
    },
  },
}
</script>
<style scoped>
  label {
    line-height: 19px;
  }
</style>