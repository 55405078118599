import { insulationThickness, getPlasterGrainsForButtons } from '@/store/filter/values'

export default {
  image: 'sw7.png',
  pdf: 'sw7.pdf',
  productData: {
    name: 'Aufbau 7: WDVS – Rein geklebte Steinwolle-Lamelle, mineralisch',
    shorthand: "sw7",
    serialNumber: 'WA-EPS-007',
    
    construction: {
      composition: [
        {
          title: { de: 'Ziel / Wunsch' },
          output: 'Technisch effizient und mit ökologischen Vorteilen',
          type: 'goal',
          value: 'goal-2'
        },
        
        {
          title: { de: 'System' },
          output: 'Lamelle geklebt',
          type: 'system', 
          value: 'system-3'
        },

        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A Mörtel Weiss',
          type: 'insulationGlue', 
          value: 'insulationGlue-1'
        },
        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A UniMörtel',
          type: 'insulationGlue', 
          value: 'insulationGlue-2'
        },
        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A LeichtMörtel',
          type: 'insulationGlue', 
          value: 'insulationGlue-3'
        },

        {
          title: { de: 'Dämmstoff' },
          output: 'ML 041',
          type: 'insulation', 
          value: 'insulation-6'
        },
        ...insulationThickness('insulation-6'),

        {
          title: { de: 'Dübel' },
          output: 'Kein Dübel',
          type: 'dowel',  
          value: null
        },

        {
          title: { de: 'Armierungsmasse' },
          output: 'K+A Mörtel Weiss',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-1'
        },
        {
          title: { de: 'Armierungsmasse' },
          output: 'K+A UniMörtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-2'
        },
        {
          title: { de: 'Armierungsmasse' },
          output: 'K+A LeichtMörtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-4'
        },

        {
          title: { de: 'Armierungsgewebe' },
          output: 'Armierungsgewebe Fein',
          type: 'reinforcingFabric', 
          value: 'reinforcingFabric-1'
        },

        {
          title: { de: 'Putzgrundierung' },
          output: 'PutzGrund',
          type: 'renderPrimer', 
          value: 'renderPrimer-1'
        },

        {
          title: { de: 'Oberputz' },
          output: 'SilikonPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-1'
        },
        
        {
          title: { de: 'Oberputz' },
          output: 'MineralPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-2'
        },
        {
          title: { de: 'Oberputz' },
          output: 'K+A UniMörtel',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-3'
        },
        ...getPlasterGrainsForButtons('finishingPlaster-1'),

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST SiliconTherm',
          type: 'facadePaint', 
          value: 'facadePaint-1'
        },

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST LotusanTherm',
          type: 'facadePaint', 
          value: 'facadePaint-2'
        },
      ],
      sum: {
        output: '330,5',
        value: 330.5
      }
    }
  }
}
