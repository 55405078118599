<template>
  <section class="row py-5" id="swk-app-wrapper">
    <StoCscFilterGrid />
    <div class="col-12">
      <div class="row">
        <button
          class="btn btn-warning mx-auto my-3 text-white"
          v-on:click="displayForm()"
          >{{ contactBtn }}</button>
      </div>
    </div>
    <StoCscResultGrid />
    <StoCscContactGrid />
  </section>
</template>

<script>

import { mapGetters } from 'vuex'

import StoCscFilterGrid from './StoCscFilter/Grid.vue'
import StoCscResultGrid from './StoCscResult/Grid.vue'
import StoCscContactGrid from './StoCscContact/Grid.vue'

// import cwcc from '../cwcc'

import { getString } from '../language'

export default {
  name: 'App',
  data: function () {
    return {
      contactBtn: getString('contact.buttonForm')
    }
  },
  components: {
    StoCscFilterGrid,
    StoCscContactGrid,
    StoCscResultGrid
  },
  computed: {
    ...mapGetters({
      itemList: 'results/items'
    })
  },
  mounted () {
    // cwcc({
    //   entrypoint: 'body',
    //   hiddenClassName: 'd-none',
    //   primaryText: getString('cwcc.primaryText'),
    //   acceptButtonText: getString('cwcc.acceptButtonText'),
    //   settingsButtonText: getString('cwcc.settingsButtonText'),
    //   secondaryText: getString('cwcc.secondaryText'),
    //   saveSettingsButtonText: getString('cwcc.saveSettingsButtonText'),
    //   cookies: [
    //     {
    //       name: 'functional',
    //       checked: true,
    //       disabled: true,
    //       label: getString('cwcc.cookies.functional.label')
    //     },
    //     {
    //       name: 'analytics',
    //       checked: false,
    //       disabled: false,
    //       label: getString('cwcc.cookies.analytics.label')
    //     }
    //   ]
    // })
  },
  methods: {
    displayForm () {
      const listLength = this.itemList.length

      const gtmLabel = String(listLength) + ' ' + getString('result.headline.countResult') + ' ' +
        (listLength > 1 ? getString('result.headline.meet.plural') : getString('result.headline.meet.singular')) +
      ' ' + getString('result.headline.selectionCriteria')

      this.$gtm.trackEvent({
        event: 'clickElement',
        category: 'kontakt',
        action: 'scrollToOpenForm',
        label: gtmLabel,
        value: null
      })

      this.$store.dispatch('contact/displayForm')
    }
  }
}

</script>
