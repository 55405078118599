<template>
  <div v-if="getWidthsForInsulation().length != 0" :class="['row', addContainerClasses]">
    <div
      class="col-12 col-md-4 col-xl-3 font-weight-bold"
      v-html="title"
    />
    <div class="col-12 col-md-8 col-xl-5">
      <div >
        <div class="row">
          <div v-for="(item, index) in getWidthsForInsulation()" :key="index" class="col-4 col-md-2">
            <button
              type="button"
              :class="[
                'btn btn-sm btn-outline-filter w-100 h-100',
                filterUiState('insulationWidth', item),
              ]"
              v-on:click="selectValue(item)"
            >
              {{ item }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import { insulationThickness } from "../../../store/filter/values";

export default {
  name: "StoCscFilterSectionInsulationWidth",
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Dämmstoffdicke in mm",
      items: insulationThickness(),
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    selectValue: function (value) {
      let title = ""; 

      const result = this.items.filter((item) => {
        return item.value === this.filterState.insulation.values[0]
      });
      const specs = result[0].specifics.filter((item) => {
        return item.width == value
      })
      
      if (result.length > 0) title = specs[0].title;

      const uiState = this.filterUiState("insulationWidth", value);
      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "insulationWidth",
          title: this.title + " " + title,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
    getWidthsForInsulation: function () {
      if (this.$store.getters['filter/state']['insulation'].values.length == 1){
        const selectedIsulation = this.filterState.insulation.values[0];
        const selectedStack = this.items.find((item) => item.value === selectedIsulation)
        
        if(selectedStack) {
          return selectedStack?.specifics.map(function(spec){
            return spec.width
          })
        } else {
          return []  
        }
        
      } else {
        return []
      }
      
    },
  },
};
</script>
