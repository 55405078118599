import Vue from 'vue'
import Vuex from 'vuex'

import createLogger from '../plugins/logger'

import products from './products'
import filter from './filter'
import results from './results'
import contact from './contact'
import stats from './stats'
Vue.config.devtools = true
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    products, // read only db structure
    filter, // states of form
    results, // filtered product list
    contact, // contact form
    stats // project specific setting form
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})

// initial set of product result list
store.dispatch(
  'results/setItems',
  Object.values(store.getters['products/items'])
)

export default store
