<template>
  <aside class="col-12 col-md-3 py-4">
    <!-- <img class="mw-100" v-bind:src="'/assets/images/swk/products/Sto-HQ-DE_GEN-0280_2018-05-01_bereinigt.png'" /> -->
  </aside>
</template>

<script>

export default {
  name: 'StoCscResultItemAside',
  props: {
    image: String
  }
}

</script>
<style scoped>
  img {
    max-height: 400px;
  }
</style>
