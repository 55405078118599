export default {

  state: state => state,

  stateFilter: state => {
    const result = {}
    for (const key in state) {
      if (key[0] !== '_' && state[key].values.length === 1) {
        result[key] = state[key]
      }
    }
    return result
  },

  uiState: state => (name, value) => {
      if (state[name] && state[name].values.indexOf(value) >= 0) {
        if (state[name].values.length > 1) return 'pending'
        else return 'active'
      }
      return ''
    
  },

  isSet: (state) => (name, value) => {
    if (state[name] && state[name].values.length > 0) {
      return state[name].values.indexOf(value) >= 0
    }
    return false
  }

}
