<template>
  <div class="col-12 pb-2 pb-md-5 filter-container">
    <!-- TODO: this is a hack to get the filter header out of the project stats component because of styling/dom issues -->
    <div class="container-fluid">
      <div
        class="row bg-appRowBg p-1 text-white font-weight-bold filter-header"
      >
        <div class="col-12 pl-0 mb-0">
          <h5 class="p-0 pl-2 m-0">1. Projekteigenschaften</h5>
        </div>
      </div>
    </div>

    <StoCscProjectStats />

    <StoCscFilterHeader />
    <div class="filter-wrapper container-fluid">
      <StoCscFilterSectionGoal addContainerClasses="pb-2 pt-4 goal" />
      <StoCscFilterSectionSystem addContainerClasses="pb-2 pt-4 border-top system" />
      <StoCscFilterSectionInsulationGlue addContainerClasses="pb-2 pt-4 border-top insulation-glue" />
      <StoCscFilterSectionInsulation addContainerClasses="pb-2 pt-4 border-top insulation" />
      <StoCscFilterSectionInsulationWidth addContainerClasses="pb-2 pt-4 border-top insulation-width" />
      <StoCscFilterSectionDowel addContainerClasses="pb-2 pt-4 border-top dowel" />
      <StoCscFilterSectionDowelEnlargement
        addContainerClasses="pb-2 pt-4 border-top dowel-enlargement"
      />
      <StoCscFilterSectionReinforcingCompound
        addContainerClasses="pb-2 pt-4 border-top reinforcing-compound"
      />
      <StoCscFilterSectionReinforcingFabric
        addContainerClasses="pb-2 pt-4 border-top reinforcing-fabric"
      />
      <StoCscFilterSectionRenderPrimer addContainerClasses="pb-2 pt-4 border-top render-primer" />
      <StoCscFilterSectionFinishingPlaster
        addContainerClasses="pb-2 pt-4 border-top finishing-plaster"
      />
      <StoCscFilterSectionPlasterGrain
        addContainerClasses="pb-2 pt-4 border-top plaster-grain"
      />
      <StoCscFilterSectionFacadePaint addContainerClasses="pb-2 pt-4 border-top" />
    </div>
  </div>
</template>

<script>
import StoCscProjectStats from './ProjectStats.vue'
import StoCscFilterHeader from './Header.vue'
import StoCscFilterSectionGoal from './Section/Goal.vue'
import StoCscFilterSectionSystem from './Section/System.vue'
import StoCscFilterSectionInsulationGlue from './Section/InsulationGlue.vue'
import StoCscFilterSectionInsulation from './Section/Insulation.vue'
import StoCscFilterSectionInsulationWidth from './Section/InsulationWidth.vue'
import StoCscFilterSectionDowel from './Section/Dowel.vue'
import StoCscFilterSectionDowelEnlargement from './Section/DowelEnlargement.vue'
import StoCscFilterSectionReinforcingCompound from './Section/ReinforcingCompound.vue'
import StoCscFilterSectionReinforcingFabric from './Section/ReinforcingFabric.vue'
import StoCscFilterSectionRenderPrimer from './Section/RenderPrimer.vue'
import StoCscFilterSectionFinishingPlaster from './Section/FinishingPlaster.vue'
import StoCscFilterSectionFacadePaint from './Section/FacadePaint.vue'
import StoCscFilterSectionPlasterGrain from './Section/PlasterGrain.vue'

export default {
  name: 'StoCscFilterGrid',
  components: {
    StoCscProjectStats,
    StoCscFilterHeader,
    StoCscFilterSectionGoal,
    StoCscFilterSectionSystem,
    StoCscFilterSectionInsulationGlue,
    StoCscFilterSectionInsulation,
    StoCscFilterSectionInsulationWidth,
    StoCscFilterSectionDowel,
    StoCscFilterSectionDowelEnlargement,
    StoCscFilterSectionReinforcingCompound,
    StoCscFilterSectionReinforcingFabric,
    StoCscFilterSectionRenderPrimer,
    StoCscFilterSectionFinishingPlaster,
    StoCscFilterSectionFacadePaint,
    StoCscFilterSectionPlasterGrain,
  },
  mounted() {
    const scrollIntoView = this.$cookies.get('sto-dk-intoview')
    const el = document.querySelector('.filter-header')
    if (el && scrollIntoView) {
      el.scrollIntoView()
    }
    if (this.$cookies.get('cwcc_functional'))
      this.$cookies.set(
        'sto-dk-intoview',
        true,
        'Fri, 31 Dec 9999 23:59:59 GMT'
      )
  },
}
</script>
