
const state = () => ({

  _callstack: [],

  goal: { values: [] },
  system: { values: [] },
  insulationGlue: { values: [] },
  insulation: { values: [] },
  insulationWidth: { values: [] },
  dowel: { values: [] },
  dowelEnlargement: { values: [] },
  reinforcingCompound: { values: [] },
  reinforcingFabric: { values: [] },
  renderPrimer: { values: [] },
  finishingPlaster: { values: [] },
  facadePaint: { values: [] },
  plasterGrain: { values: [] }
})

export default state
