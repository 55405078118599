<template>
  <div :class="['row', addContainerClasses]">
    <div class="col-12 col-md-4 col-xl-3 font-weight-bold" >
      <Popover info="insulationGlue" :title="title"/>
    </div>
    <div class="col-12 col-md-8 col-xl-5">
      <div class="row">
        <div v-for="(item, index) in items" :key="index" class="col-12 col-md-4">
          <button
            type="button"
            :class="[
              'btn btn-sm btn-outline-filter w-100 h-100',
              filterUiState('insulationGlue', item.value),
            ]"
            v-on:click="selectValue(item.value)"
          >
            {{ item.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import Popover from '../../Popover.vue'

export default {
  name: "StoCscFilterSectionInsulationGlue",
  components: {
    Popover
  },
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Dämmstoffkleber",
      items: [
        { title: "K+A Mörtel Weiss", value: "insulationGlue-1" },
        { title: "K+A UniMörtel", value: "insulationGlue-2" },
        { title: "K+A LeichtMörtel", value: "insulationGlue-3" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    selectValue: function (value) {
      let title = "";
      const result = this.items.filter((item) => item.value === value);
      if (result.length > 0) title = result[0].title;

      const uiState = this.filterUiState("insulationGlue", value);

      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "insulationGlue",
          title: this.title + " " + title,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
  },
};
</script>
