<template>
  <div data-target="contact-form" :class="['col-12 py-5 pt-lg-0', !displayContactForm ? 'text-center' : '']">
    <button
      v-if="!displayContactForm"
      class="btn btn-warning text-white"
      v-on:click="displayForm()"
    >{{ contactBtn }}</button>
    <StoCscContactForm v-if="displayContactForm" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import StoCscContactForm from './Form.vue'

import { getString } from '../../language'

export default {
  name: 'StoCscContactGrid',
  data: function () {
    return {
      contactBtn: getString('contact.buttonForm')
    }
  },
  components: {
    StoCscContactForm
  },
  computed: {
    ...mapGetters({
      displayContactForm: 'contact/displayForm',
      itemList: 'results/items'
    })
  },
  methods: {
    displayForm () {
      const listLength = this.itemList.length

      const gtmLabel = String(listLength) + ' ' + getString('result.headline.countResult') + ' ' +
        (listLength > 1 ? getString('result.headline.meet.plural') : getString('result.headline.meet.singular')) +
      ' ' + getString('result.headline.selectionCriteria')

      this.$gtm.trackEvent({
        event: 'clickElement',
        category: 'kontakt',
        action: 'openForm',
        label: gtmLabel,
        value: null
      })

      this.$store.dispatch('contact/displayForm')
    }
  }
}

</script>
