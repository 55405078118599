<template>
  <div :class="['row', addContainerClasses]">
    <div class="col-12 col-md-4 col-xl-3 font-weight-bold">
      
      <Popover info="facadePaint" :title="title"/>
      <div v-if="filterState.finishingPlaster.values[0] === 'finishingPlaster-3'" class="text-danger small">
         {{messages.chooseFinishingPlaster}}
      </div>
    </div>
    <div class="col-12 col-md-8 col-xl-5">
      <div class="row">
        <div v-for="(item, index) in items" :key="index" class="col-12 col-md-6">
          <button
            type="button"
            :class="[
              'btn btn-sm btn-outline-filter w-100 h-100',
              filterUiState('facadePaint', item.value),
            ]"
            v-on:click="selectValue(item.value)"
          >
            {{ item.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import { getString } from "../../../language";
import Popover from '../../Popover.vue'

export default {
  name: "StoCscFilterSectionFacadePaint",
  components: {
    Popover
  },
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Fassadenfarbe",
      items: [
        { title: "SÜDWEST SiliconTherm", value: "facadePaint-1" },
        { title: "SÜDWEST LotusanTherm", value: "facadePaint-2" },
      ],
      messages: {
        chooseFinishingPlaster:  getString("strings.messages.choose-finishingPlaster"), 
      }
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    selectValue: function (value) {
      let title = "";
      const result = this.items.filter((item) => item.value === value);
      if (result.length > 0) title = result[0].title;

      const uiState = this.filterUiState("facadePaint", value);

      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "facadePaint",
          title: this.title + " " + title,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
  },
};
</script>
