
const popoverIntro = {

  el: document.querySelector('[data-popover="how-it-works"]'),

  show () {
    this.el.classList.remove('d-none')
  },

  hide () {
    this.el.classList.add('d-none')
  }

}

document.querySelector('[data-section="intro"]').addEventListener('mouseleave', function (event) {
  popoverIntro.hide()
})

document.querySelector('.popover-trigger').addEventListener('mouseover', function () {
  popoverIntro.show()
})

document.querySelector('[data-popover-close="how-it-works"]').addEventListener('click', function () {
  popoverIntro.hide()
})
