import { insulationThickness, getPlasterGrainsForButtons } from '@/store/filter/values'

export default {
  image: 'sw12.png',
  pdf: 'sw12.pdf',
  productData: {
    name: 'Aufbau 12: Sanierung alter Fassaden ohne Dämmung, rein organisch',
    shorthand: "sw12",
    serialNumber: 'WA-EPS-012',
    
    construction: {
      composition: [
        {
          title: { de: 'Ziel / Wunsch' },
          output: 'Sanierung alter Fassaden ohne Dämmung',
          type: 'goal',
          value: 'goal-3'
        },

        {
          title: { de: 'Armierungsmasse' },
          output: 'FlexSpachtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-3'
        },

        {
          title: { de: 'Armierungsgewebe' },
          output: 'Armierungsgewebe Fein',
          type: 'reinforcingFabric', 
          value: 'reinforcingFabric-1'
        },

        {
          title: { de: 'Putzgrundierung' },
          output: 'PutzGrund',
          type: 'renderPrimer', 
          value: 'renderPrimer-1'
        },

        {
          title: { de: 'Oberputz' },
          output: 'SilikonPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-1'
        },
        ...getPlasterGrainsForButtons('finishingPlaster-1'),
        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST SiliconTherm',
          type: 'facadePaint', 
          value: 'facadePaint-1'
        },

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST LotusanTherm',
          type: 'facadePaint', 
          value: 'facadePaint-2'
        },
      ],
      sum: {
        output: '330,5',
        value: 330.5
      }
    }
  }
}
