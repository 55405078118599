<template>
  <div class="col-12">
    <StoCscResultHeader v-bind:listLength="itemList.length" />
    <div class="row px-4 py-5 pt-lg-0">
      
      <StoCscResultItemGrid
        v-for="(item, index) in itemList"
        :key="index"
        v-bind:item="item"
        v-bind:index="index"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import StoCscResultHeader from './Header.vue'
import StoCscResultItemGrid from './Item/Grid.vue'

export default {
  name: 'StoCscResultGrid',
  components: {
    StoCscResultHeader,
    StoCscResultItemGrid
  },
  computed: {
    ...mapGetters({
      itemList: 'results/items'
    })
  }
}

</script>
