import { insulationThickness, getPlasterGrainsForButtons } from '@/store/filter/values'

export default {
  image: 'sw2.png',
  pdf: 'sw2.pdf',
  productData: {
    name: 'Aufbau 2: WDVS – EPS rein organisch, geklebt',
    shorthand: "sw2",
    serialNumber: 'WA-EPS-002',
    
    construction: {
      composition: [
        {
          title: { de: 'Ziel / Wunsch' },
          output: 'Effizient Energie sparen',
          type: 'goal',
          value: 'goal-1'
        },
        
        {
          title: { de: 'System' },
          output: 'EPS geklebt',
          type: 'system', 
          value: 'system-2'
        },

        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A Mörtel Weiss',
          type: 'insulationGlue', 
          value: 'insulationGlue-1'
        },
        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A UniMörtel',
          type: 'insulationGlue', 
          value: 'insulationGlue-2'
        },
        {
          title: { de: 'Dämmstoffkleber' },
          output: 'K+A LeichtMörtel',
          type: 'insulationGlue', 
          value: 'insulationGlue-3'
        },

        {
          title: { de: 'Dämmstoff' },
          output: 'EPS 032',
          type: 'insulation', 
          value: 'insulation-1'
        },
        {
          title: { de: 'Dämmstoff' },
          output: 'EPS 032 Nut und Feder',
          type: 'insulation', 
          value: 'insulation-2'
        },
        {
          title: { de: 'Dämmstoff' },
          output: 'EPS 034',
          type: 'insulation', 
          value: 'insulation-3'
        },
        {
          title: { de: 'Dämmstoff' },
          output: 'EPS 034 Nut und Feder',
          type: 'insulation', 
          value: 'insulation-4'
        },
        ...insulationThickness('insulation-1'),
        ...insulationThickness('insulation-2'),
        ...insulationThickness('insulation-3'),
        ...insulationThickness('insulation-4'),
        {
          title: { de: 'Dübel' },
          output: 'Kein Dübel',
          type: 'dowel',  
          value: null
        },
        
        {
          title: { de: 'Armierungsmasse' },
          output: 'FlexSpachtel',
          type: 'reinforcingCompound', 
          value: 'reinforcingCompound-3'
        },

        {
          title: { de: 'Armierungsgewebe' },
          output: 'Armierungsgewebe Fein',
          type: 'reinforcingFabric', 
          value: 'reinforcingFabric-1'
        },

        {
          title: { de: 'Putzgrundierung' },
          output: 'PutzGrund',
          type: 'renderPrimer', 
          value: 'renderPrimer-1'
        },

        {
          title: { de: 'Oberputz' },
          output: 'SilikonPutz',
          type: 'finishingPlaster', 
          value: 'finishingPlaster-1'
        },
        ...getPlasterGrainsForButtons('finishingPlaster-1'),
        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST SiliconTherm',
          type: 'facadePaint', 
          value: 'facadePaint-1'
        },

        {
          title: { de: 'Fassadenfarbe' },
          output: 'SÜDWEST LotusanTherm',
          type: 'facadePaint', 
          value: 'facadePaint-2'
        },
      ],
      sum: {
        output: '330,5',
        value: 330.5
      }
    }
  }
}
