<template>
  <article class="col-12 py-5 border-bottom ">
    <header class="d-none d-md-flex row font-weight-bold bg-gray text-black justify-content-between">
      <div class="col-auto col-md-9 d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <button
            role="button"
            v-on:click="open = !open"
            target="_blank"
            class="ml-n3 mr-3 btn btn-sm btn-blue py-2 px-3 h-100"
          >
            <span v-if="!open">+</span>
            <span v-else>-</span>
          </button>
          <div>{{ item.productData.name }}</div>
        </div>
        <div>
          <span>Material&shy;einsatz</span>
        </div>
      </div>
      <div class="col-auto col-md-3">
        
        <span
          role="button"
          v-on:click="onClickPDF(item.productData.name)"
          target="_blank"
          class="btn btn-sm btn-blue h-100"
        >
          {{ buttonPDF }}
        </span>
      </div>
    </header>

    <header class="d-flex d-md-none row font-weight-bold bg-gray text-white justify-content-between">
      <div class="w-100">
        <div class="d-flex justify-content-between">
        <button
            role="button"
            v-on:click="open = !open"
            target="_blank"
            class="btn btn-sm btn-blue py-2 px-3 h-100"
          >
          <span v-if="!open">+</span>
            <span v-else>-</span>
        </button>
        <span
          
          
          role="button"
          v-on:click="onClickPDF(item.productData.name)"
          target="_blank"
          class="btn btn-sm btn-blue h-100"
        >
          {{ buttonPDF }}
        </span>
        </div>
        <div class="d-flex justify-content-between my-2">
            <div class="col d-flex align-items-centertext-right">
                
              <div>{{ item.productData.name }}</div>
            </div>
            <div class="col-auto text-right">
              <span>Material&shy;einsatz</span>
            </div>
        </div>
      </div>
      
       
    </header>

    <section class="row list" :class="open ? '' : 'closed'">
      <StoCscResultItemComposition
        v-bind:construction="item.productData.construction" :title="item.productData.name" :shorthand="item.productData.shorthand"
      />
      <StoCscResultItemAside v-bind:image="item.image" />
    </section>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import StoCscResultItemAside from "./Aside.vue";
import StoCscResultItemComposition from "./Composition.vue";
import { getString } from "../../../language";


export default {
  name: "StoCscResultItemGrid",
  props: {
    item: Object,
    index: Number,
  },
  data: function () {
    return {
      buttonPDF: getString("result.buttonPDF"),
      open: (this.index!= 0) ? true : true
    };
  },
  computed: {
    ...mapGetters({
      formData: 'stats/formData',
      formState: 'stats/formState',
    }),
  },
  components: {
    StoCscResultItemAside,
    StoCscResultItemComposition,
  },
  methods: {
    onClickPDF: function (name) {
      this.$gtm.trackEvent({
        event: "clickElement",
        category: "pdf",
        action: "download",
        label: name + "",
        value: null,
      });


      
      this.$store.dispatch('filter/downloadPdf', {
        shorthand: this.item.productData.shorthand,
        projectName: this.formData.name
      })
    
    },
    toggleCollapse: () => {
      
    }
  },
};
</script>
<style scoped>
.list {
    transition: max-height 0.7s ease;
    max-height: 2000px;
    overflow: hidden;

}
  .closed {
    max-height: 0px;
    
  }
</style>
