<template>
  <div :class="['row', addContainerClasses]">
    <div class="col-12 col-md-4 col-xl-3 font-weight-bold" >
      <Popover info="insulation" :title="title"/>
    </div>
    <div class="col-12 col-md-8 col-xl-5">
      <div class="row">
        <div v-for="(item, index) in items" :key="index" class="col-12 col-md-4">
            <button type="button"
            :class="['btn btn-sm btn-outline-filter w-100 h-100', filterUiState('insulation', item.value)]"
            v-on:click="selectValue(item.value)">{{ item.title }}</button>
          </div>
      </div>
      
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import { trackCallstack } from '../../../helper/tracker'
import Popover from '../../Popover.vue'

export default {
  name: 'StoCscFilterSectionInsulation',
  components: {
    Popover
  },
  props: {
    addContainerClasses: String
  },
  data: function () {
    return {
      title: 'Dämmstoff',
      items: [
        { title: 'EPS 032', value: 'insulation-1' },
        { title: 'EPS 032 Nut und Feder', value: 'insulation-2' },
        { title: 'EPS 034', value: 'insulation-3' },
        { title: 'EPS 034 Nut und Feder', value: 'insulation-4' },
        { title: 'MP 035', value: 'insulation-5' },
        { title: 'ML 041', value: 'insulation-6' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      filterUiState: 'filter/uiState',
      filterState: 'filter/state'
    })
  },
  methods: {
    selectValue: function (value) {
      let title = ''
      const result = this.items.filter(item => item.value === value)
      if (result.length > 0) title = result[0].title

      const uiState = this.filterUiState('insulation', value)

      this.$gtm.trackEvent({
        event: 'filter',
        category: this.title,
        action: uiState === 'pending' ? 'hinzufügen' : (uiState === 'active' ? 'reaktivieren' : 'aktivieren'),
        label: title,
        value: value
      })

      this.$store.dispatch('filter/setValue', {
        name: 'insulation',
        title: this.title + ' ' + title,
        value: value
      }).then(() => trackCallstack(this.$gtm, this.filterState._callstack))
    }
  }
}

</script>
