import { getCompositionParts } from "./values";

const compositionSectionCompareGreaterOrEqual = (type, item, value) => {
  const search = value.split("-");
  const parts = getCompositionParts(item, type);
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].value === value) return true; // direct match
    const subject = String(parts[i].value).split("-");
    if (
      subject.length === 2 &&
      search[0] === subject[0] &&
      Number(search[1]) <= Number(subject[1])
    )
      return true;
  }
  return false;
};

const compositionSectionCompareLowerOrEqual = (type, item, value) => {
  const search = value.split("-");
  const parts = getCompositionParts(item, type);
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].value === value) return true; // direct match
    const subject = String(parts[i].value).split("-");
    if (
      subject.length === 2 &&
      search[0] === subject[0] &&
      Number(search[1]) >= Number(subject[1])
    )
      return true;
  }
  return false;
};

const compositionSectionCompareEqual = (type, item, value) => {
  if (value === null) {
    return true;
  }
  const search = value.split("-");
  const parts = getCompositionParts(item, type);
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].value === value) return true; // direct match
    const subject = String(parts[i].value).split("-");
    if (
      subject.length === 2 &&
      search[0] === subject[0] &&
      Number(search[1]) === Number(subject[1])
    )
      return true;
  }
  return false;
};

const compositionCompareGreaterOrEqual = (type, item, value) => {
  const parts = getCompositionParts(item, type);
  if (value === null) {
    return parts.length <= 0;
  } else {
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].value >= value) {
        return true;
      }
    }
  }
  return false;
};

const compositionCompareLowerOrEqual = (type, item, value) => {
  const parts = getCompositionParts(item, type);
  if (value === null) {
    return parts.length <= 0;
  } else {
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].value <= value) {
        return true;
      }
    }
  }
  return false;
};

const compositionCompareEqual = (type, item, value) => {
  const parts = getCompositionParts(item, type);
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].value === value) {
      return true;
    }
  }
  return false;
};

export default {
  goal: (item, value) => compositionSectionCompareEqual("goal", item, value),
  system: (item, value) => compositionSectionCompareEqual("system", item, value),
  insulationGlue: (item, value) => compositionSectionCompareEqual("insulationGlue", item, value),
  insulation: (item, value) => compositionSectionCompareEqual("insulation", item, value),
  dowel: (item, value) => compositionSectionCompareEqual("dowel", item, value),
  dowelEnlargement: (item, value) => compositionSectionCompareEqual("dowelEnlargement", item, value),
  reinforcingCompound: (item, value) => compositionSectionCompareEqual("reinforcingCompound", item, value),
  reinforcingFabric: (item, value) => compositionSectionCompareEqual("reinforcingFabric", item, value),
  renderPrimer: (item, value) => compositionSectionCompareEqual("renderPrimer", item, value),
  finishingPlaster: (item, value) => compositionSectionCompareEqual("finishingPlaster", item, value),
  facadePaint: (item, value) => compositionSectionCompareEqual("facadePaint", item, value),
  // plasterGrain: (item, value) => compositionCompareEqual("plasterGrain", item, value),

  // pinsulation: (item, value) => compositionCompareEqual('pinsulation', item, value),
  // outerPlanking: (item, value) => compositionCompareEqual('outerPlanking', item, value),
  // innerPlanking1: (item, value) => compositionSectionCompareEqual('innerPlanking1', item, value),
  // innerPlanking2: (item, value) => compositionSectionCompareEqual('innerPlanking2', item, value),
  // innerPlanking3: (item, value) => compositionSectionCompareEqual('innerPlanking3', item, value),

  // stand: (item, value) => compositionCompareEqual('stand', item, value),

  // compartmentInsulation: (item, value) => compositionSectionCompareLowerOrEqual('compartmentInsulation', item, value),
};
