
const state = () => ({

  items: []

})

const getters = {

  items: state => state.items

}

const mutations = {

  setItems (state, items) {
    state.items = items
  }

}

const actions = {

  setItems (context, items) {
    return context.commit('setItems', items)
  },

  filterProducts (context, filterMap) {
    let items = Object.values(context.rootGetters['products/items'])

    for (const name in filterMap) {
      const map = filterMap[name]
      if (map.values.length == 1) { // only filter if exactly one value exists
        items = items.filter(item => map.callback(item, map.values[0]))
      } // -> because of implication of filter by results
      if (items.length <= 0) break
    }

    return context.commit('setItems', items)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
