<template>
  <div class="container-fluid">
    <div class="row bg-appRowBg p-1 text-white font-weight-bold filter-header">
      <div class="pl-0 col-6">
        <h5 class="p-0 pl-2 m-0">2. {{ headline }}</h5>
      </div>
      <div class="col-3">
        <h5 class="link p-0 m-0" v-on:click="resetFilter()">{{ reset }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { getString } from '../../language'

export default {
  name: 'StoCscFilterHeader',
  data: function () {
    return {
      headline: getString('filter.headline'),
      reset: getString('filter.reset'),
    }
  },
  methods: {
    resetFilter: function (value) {
      this.$gtm.trackEvent({
        event: 'clickElement',
        category: 'eigenschaften',
        action: 'button',
        label: 'Eigenschaften zurücksetzen [X]',
        value: null,
      })

      this.$store.dispatch('filter/resetFilter')
      this.$store.dispatch('contact/hideForm')
    },
  },
}
</script>
