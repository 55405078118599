<template>
  <div>
    <p>{{ content }}</p>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import { getString, getSectionTitle, getSectionValueTitle } from '../../language'

export default {
  name: 'StoCscContactSummary',
  data: function () {
    return {
      content: getString('contact.summary.content'),
    }
  },
  computed: {
    ...mapGetters({
      stateFilter: 'filter/stateFilter',
      resultItems: 'results/items',
      formData: 'contact/formData'
    }),
    stateFilterLength: function () {
      return Object.keys(this.stateFilter).length
    }
  },
  methods: {
    format (item, name) {
      return getSectionTitle(name) + ': ' + getSectionValueTitle(name, item.values[0])
    }
  }
}
</script>
