<template>
  <section class="col-12 col-md-9 mt-2">
    <!-- <div class="row border-bottom font-weight-bold">
      <div class="col-8 py-1" v-html="constructionTitle" />
      <div class="col-4 py-1" v-html="constructionValueTitle" />
    </div> -->
    <div
      class="row composition-row"
      v-for="(part, index) in composition"
      :key="index"
      :class="  {
        'font-weight-bold': part.cssClasses == 'bold',
        'd-none': part.cssClasses == 'd-none',
        'first-of-type':
          index == 0 ||
          (index > 0 && composition[index - 1].title.de !== part.title.de),
        [part.title.de.toLowerCase()]: true,
      }"
    >
      <div
        class="col-12 col-md-4 py-1 composition-title font-weight-bold"
        v-html="part.title.de"
      />
      <div
        class="col-6 col-md-4 py-1 composition-output border-bottom"
        v-html="part.output"
      />
      <div
        class="col-6 col-md-4 py-1 d-flex justify-content-end align-self-end composition-material-usage border-bottom"
      >
        {{ part.materialUsage }}
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { getString } from "../../../language";
import { coefficients, dowelLength } from "@/store/filter/values";

export default {
  name: "StoCscResultItemComposition",
  props: {
    construction: Object,
    title: String,
    shorthand: String,
  },
  data: function () {
    return {
      constructionTitle: getString("result.construction.title"),
      constructionValueTitle: getString("result.construction.valueTitle"),
      constructionSum: getString("result.construction.sum"),
      allCoefficients: coefficients(),
    };
  },
  computed: {
    composition() {
      // Goal, System, Insulation and FinishingPlaster should only show the selected if a selection in this Types was made.
      const excludeThis = ["finishingPlaster", "plasterGrain"];

      const composition = this.construction.composition.filter((obj) => {
        if (excludeThis.includes(obj.type)) {
          if (
            this.$store.getters["filter/state"][obj.type]?.values[0] !==
              obj.value &&
            this.$store.getters["filter/state"][obj.type]?.values.length != 0
          ) {
            return false;
          }
        }
        return (
          obj.output !== null &&
          obj.title.de !== getString("insulationThickness") &&
          obj.title.de !== "Dämmstoffbreite"
        );
      }); // todo filter by current language instead of static de
      // after the last item with title 'Dämmstoff', add Dämmstoffdicke as new item with title 'Dämmstoffdicke'
      // and value from store.filter.insulationWidth because it is a dynamic value according to the pdf
      const insulationIndex = composition
        .map((obj) => obj.title.de)
        .lastIndexOf("Dämmstoff"); // todo i18n
      if (
        insulationIndex !== -1 &&
        this.$store.getters["filter/state"].insulationWidth?.values.length ==
          1 &&
        this.$store.getters["filter/state"].insulation.values.length == 1
      ) {
        composition.splice(insulationIndex + 1, 0, {
          title: {
            de: getString("insulationThickness"),
          },
          cssClasses: "bold",
          type: "insulationThickness",
          output: this.$store.getters["filter/state"].insulationWidth.values[0],
        });
        const dowelLengthItem = dowelLength(
          this.$store.getters["filter/state"].insulation.values[0],
          this.$store.getters["filter/state"].insulationWidth.values[0]
        );

        composition.splice(insulationIndex + 2, 0, {
          title: {
            de: getString("dowelLength"),
          },
          cssClasses: "bold",
          type: "dowelLength",
          output: dowelLengthItem.value,
        });
      } else if (insulationIndex !== -1) {
        composition.splice(insulationIndex + 1, 0, {
          title: {
            de: getString("insulationThickness"),
          },

          output: "Dämmstoff und Dämmstoffdicke auswählen",
        });
      }

      // wenn Dübellänge = “Dübel technisch nicht möglich" oder “Kein Dübel möglich" dann Dübel aus der auswertung unten rausnehmen

      const noDowelOutput = composition.find((obj) => obj.type === "dowelLength")?.output;
      const noDowel = typeof noDowelOutput === "string"

      const isFinishingPlaster3 = composition.find((obj) => obj.value === "finishingPlaster-3");
      console.log(isFinishingPlaster3)

      composition.map((obj) => {
        // deconstruct type and value from the object
        const { type, value } = obj;
        // if the type is the same as the current filter state, add the class 'bold'
        if (
          this.$store.getters["filter/state"][type]?.values.length === 1 &&
          this.$store.getters["filter/state"][type]?.values[0] === value
        ) {
          obj.cssClasses = "bold";
        } else {
          if (!["insulationThickness", "dowelLength"].includes(obj.type)) {
            obj.cssClasses = "";
          }
        }

         

        if (this.allCoefficients[value]) {
          obj.materialUsage =
            (
              this.$store.getters["stats/formData"].size *
              this.allCoefficients[value][0]
            ).toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) +
            " " +
            this.allCoefficients[value][1];
        }

        if (isFinishingPlaster3) {
          if (obj.type === 'plasterGrain') {
            obj.cssClasses = "d-none"
          }
        }


        if (noDowel) {
          if (obj.type === "dowelLength") {
            obj.cssClasses = "d-none"
          } else if (obj.type === "dowel") {
            obj.cssClasses =  "bold"
            obj.output = noDowelOutput
          }
        }
      });
      // console.log(composition)
      this.$store.dispatch("filter/setCompositionForPdf", {
        name: this.shorthand,
        title: this.title,
        value: composition,
      });
      return composition;
    },
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.composition-row.first-of-type {
  border-top: 1px solid #e0e0e0;
}

.composition-row.font-weight-bold .composition-output,
.composition-row.font-weight-bold .composition-material-usage,
.composition-row .font-weight-bold {
  color: black;
}
.composition-row .composition-output,
.composition-row .composition-material-usage {
  color: #bccad5;
}

/* .composition-row.insulation:not(.font-weight-bold) {
    display: none;
  }

  .composition-row.insulation:not(.font-weight-bold) .composition-title {
    opacity: 1;
  } */
</style>