
import SW1 from './sw1'
import SW2 from './sw2'
import SW3 from './sw3'
import SW4 from './sw4'
import SW5 from './sw5'
import SW6 from './sw6'
import SW7 from './sw7'
import SW8 from './sw8'
import SW9 from './sw9'
import SW10 from './sw10'
import SW11 from './sw11'
import SW12 from './sw12'

const state = () => ({
  items: {
    SW1,
    SW2,
    SW3,
    SW4,
    SW5,
    SW6,
    SW7,
    SW8,
    SW9,
    SW10,
    SW11,
    SW12
  }
})

const getters = {
  items: state => state.items
}

export default {
  namespaced: true,
  state,
  getters
}
