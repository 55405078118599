
export default {

  displayForm: state => state.displayForm,

  formData: state => state.formData,

  formState: state => {
    return {
      sending: state.formSending,
      error: state.formError,
      alertMessage: state.formAlertMessage,
      success: state.formSuccess
    }
  }
}
