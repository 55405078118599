
const state = () => ({

  // @ TODO, implement form states
  // valid: false, // flag for send btn activation
  // sending: false, // flag to detect pending request

  displayForm: false,
  formSuccess: false,
  formError: false,
  formAlertMessage: '',
  formSending: false,

  formData: {
    firstname: '',
    lastname: '',
    company: '',
    street: '',
    postcode: '',
    city: '',
    phone: '',
    email: '',
    text: '', 
    dealer: ''
  }

})

export default state
