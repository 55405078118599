var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"col-12 col-md-9 mt-2"},_vm._l((_vm.composition),function(part,index){return _c('div',{key:index,staticClass:"row composition-row",class:{
      'font-weight-bold': part.cssClasses == 'bold',
      'd-none': part.cssClasses == 'd-none',
      'first-of-type':
        index == 0 ||
        (index > 0 && _vm.composition[index - 1].title.de !== part.title.de),
      [part.title.de.toLowerCase()]: true,
    }},[_c('div',{staticClass:"col-12 col-md-4 py-1 composition-title font-weight-bold",domProps:{"innerHTML":_vm._s(part.title.de)}}),_c('div',{staticClass:"col-6 col-md-4 py-1 composition-output border-bottom",domProps:{"innerHTML":_vm._s(part.output)}}),_c('div',{staticClass:"col-6 col-md-4 py-1 d-flex justify-content-end align-self-end composition-material-usage border-bottom"},[_vm._v(" "+_vm._s(part.materialUsage)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }