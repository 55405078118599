<template>
  <div v-if="getPlasterGrains().length > 0" :class="['row', addContainerClasses]">
    <div
      class="col-12 col-md-4 col-xl-3 font-weight-bold"
      v-html="title"
    />
    <div class="col-12 col-md-8 col-xl-9">
      <div >
        <button
          type="button"
          v-for="item in getPlasterGrains()"
          :key="item.value"
          :class="[
            'btn btn-sm btn-outline-filter',
            filterUiState('plasterGrain', item.value),
          ]"
          v-on:click="selectValue(item.value)"
        >
          {{ item.output }}
        </button>
      </div>
      <!-- <button type="button"
        v-for="(item, index) in items"
        :key="index"
        :class="['btn btn-sm btn-outline-filter', filterUiState('insulation', item.value)]"
        v-on:click="selectValue(item.value)">{{ item.title }}</button> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { trackCallstack } from "../../../helper/tracker";
import { getPlasterGrainsForButtons } from "../../../store/filter/values";

export default {
  name: "StoCscFilterSectionPlasterGrain",
  props: {
    addContainerClasses: String,
  },
  data: function () {
    return {
      title: "Oberputzkörnung"
    };
  },
  computed: {
    ...mapGetters({
      filterUiState: "filter/uiState",
      filterState: "filter/state",
    }),
  },
  methods: {
    selectValue: function (value) {
      let title = ""; 
      const result = this.getPlasterGrains().filter((item) => {
        return item.value === this.filterState.plasterGrain.values[0]
      });
      if (result.length > 0) title = result[0].title;

      const uiState = this.filterUiState("plasterGrain", value);
      this.$gtm.trackEvent({
        event: "filter",
        category: this.title,
        action:
          uiState === "pending"
            ? "hinzufügen"
            : uiState === "active"
            ? "reaktivieren"
            : "aktivieren",
        label: title,
        value: value,
      });

      this.$store
        .dispatch("filter/setValue", {
          name: "plasterGrain",
          title: this.title + " " + value,
          value: value,
        })
        .then(() => trackCallstack(this.$gtm, this.filterState._callstack));
    },
    getPlasterGrains: function () {
      if (this.$store.getters['filter/state']['finishingPlaster'].values.length == 1){
        return getPlasterGrainsForButtons(this.$store.getters['filter/state']['finishingPlaster'].values[0])
      } else {
        return []
      }
      
    },
  },
};
</script>
